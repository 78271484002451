<!--  -->
<template>
  <div class="home" ref="wrapper">
      <h3>{{data.Title}}</h3>
      <div class="mark">
        <div class="time"><img src="../../assets/time.png" width="15"><div> {{formatTime(data.Time)}}</div> </div>
        <div><img src="../../assets/eye.png" width="15"><div> {{data.Click}}人观看</div></div>
      </div>
      
    <div id="quill_box"></div>
  </div>
</template>

<script>
import { getArticle } from "@/api/userApp";
import {timeFormat} from "@/utils/timeUtil"
import Quill from "quill";

export default {
  components:{
  },
  data() {
    return {
        data:"",
    };
  },
  computed: {
          formatTime() {
      return function (value) {
        return timeFormat(value);
      };
    },
  },
  watch: {},
  methods: {
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
  
  mounted() {

    this.quill = new Quill("#quill_box", {});
    this.quill.enable(false); 
    this.id = this.getQueryVariable("Id");
   console.log("11111");
    getArticle(this.id).then((resp)=>{
        this.data = resp;
       let json = JSON.parse(this.data.Content);
       for(var i=0;i<json.length;i++){
           if(json[i].insert && json[i].insert.image){
            json[i].insert.image = `${process.env.VUE_APP_CDNURL}${json[i].insert.image}`
            console.log(json[i].image);
         }  
       }
       console.log("json",json)
        this.quill.setContents(json);
    });

  },
};
</script>
<style lang='scss' >
.home {
  overflow: scroll;
}
.mark{
  margin-right: 15px;
  margin-left: 15px;
  color: gray;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  div{
      display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  :nth-child(1){
    margin-right: 2px;
  }
  }
}

.ql-container{
    font-size: 16px;
}
.employee{
    text-align:right;
}
  h4{
   text-align: left;
  }
  .ql-editor .ql-video {
  display: block;
  max-width: 100%;
  object-fit:fill;
	width:400px;
	height:320px;
  margin: 10px 0;
}

</style>